/* LoginForm.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f0f4f8;
  font-family: 'Roboto', sans-serif;
}

.login-container {
  display: flex;
  height: 100vh;
}

.login-color {
  flex: 1;
  background: linear-gradient(135deg, #037C74, #65C1C2);
  display: flex;
  align-items: center;
  justify-content: center;
}


.login-form {
  max-width: 900px;
  padding: 100px;
  color: white;
}

.login-form h2 {
  text-align: center;
  color: #037C74;
  font-size: 28px;
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  letter-spacing: 1.2px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffffff;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #94D6DA;
  box-sizing: border-box;
  border-radius: 5px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input:focus {
  border-color: #ffffff;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  outline: none;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  text-align: center;
}

.login-container .l-submit-button {
  width: 100%!important;
  padding: 12px!important;
  border: none!important;
  background-color: #037C74 !important;
  color: white!important;
  font-size: 18px!important;
  cursor: pointer!important;
  border-radius: 5px!important;
  transition: background-color 0.3s, transform 0.3s!important;
}

.l-submit-button:hover {
  background-color: #023e3a!important;
  transform: scale(1.02)!important;
}

@media screen and (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-color {
    display: none;
  }

  .login-form {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
}

.logo {
  max-width: 200px; /* Ajuste o tamanho máximo da logo conforme necessário */
  height: auto; /* Mantém a proporção da imagem */
  right: 10px;

}

.links {
  font-size: 16px;
  color: #037C74; /* Cor base para o texto */
  text-align: center;
  margin-top: 20px;
}

.link-highlight {
  color: #023e3a; /* Cor mais forte para o link */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
}

.link-highlight:hover {
  color: #037C74; /* Cor mais clara ao passar o mouse */
  border-bottom: 2px solid #037C74; /* Sublinhado ao passar o mouse */
}

/* Ajustes para dispositivos móveis */
@media screen and (max-width: 768px) {
  .login-container {
    flex-direction: column;
    padding: 20px;
  }

  .login-color {
    display: none;
  }

  .login-form {
    width: 100%;
    padding: 20px;
    max-width: 100%;
  }

  .login-form h2 {
    font-size: 24px;
    margin-bottom: 20px;

  }

  .form-group input {
    padding: 8px;
    position: relative;right: 20%;
  }

  .form-group label {
    position: relative;right: 20%;
  }

  .submit-button {
    padding: 10px;
    font-size: 16px;
    margin-top: 20px;
  }

  .logo {
    max-width: 150px; /* Reduz o tamanho da logo */
    margin-bottom: 20px; /* Adiciona espaço abaixo da logo */
    position: relative;
    left: -60%;
    top: -30%;
    width: 120px; /* Ajuste a largura conforme necessário */
    height: auto; /* Mantém a proporção da imagem */
  }
}
