/* HomePage.css */

.page-container {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  padding: 30px;
  transition: margin-left 0.3s;
}

.sidebar.collapsed ~ .main-content {
  margin-left: 80px; /* Ajustar conforme a largura minimizada */
}

.h-user-info {
  display: flex;
  align-items: center;
}

.h-user-info img {
  border-radius: 50%;
  margin-right: 10px;
}