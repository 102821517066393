/* ContasReceberAvulsoForm.css */

/* Container principal: Sidebar + conteúdo */
.cpa-main-form-container {
    display: flex;
    background-color: #f7f7f7;
    min-height: 100vh;
  }
  
  /* Área principal (conteúdo) */
  .cpa-main-content {
    flex-grow: 1;
    padding: 20px;
    width: calc(100% - 250px); /* considerando sidebar com 250px */
    overflow-y: auto;
  }
  
  /* Container do formulário: ocupa mais espaço e centralizado */
  .cpa-cliente-form-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(148,146,146,0.1);
    margin: 20px auto;
    width: 100%;
    max-width: 1700px !important;
  }
  
  /* Cabeçalho do formulário */
  .cpa-cliente-form-container h2 {
    font-size: 24px;
    color: #2C3E50;
    margin-bottom: 20px;
  }
  
  /* Espaçamento dos grupos que não estão em linha */
  .conta-receber-avulso-form .form-group {
    margin-bottom: 20px;
  }
  
  /* Linha do formulário: organiza os campos em linha */
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  /* Em linhas, cada grupo deve ocupar o mesmo espaço */
  .form-row .form-group {
    flex: 1;
  }
  
  /* Ajusta inputs, selects e DatePicker para uma aparência uniforme */
  .conta-receber-avulso-form input[type="text"],
  .conta-receber-avulso-form input[type="number"],
  .conta-receber-avulso-form input[type="date"],
  .conta-receber-avulso-form select,
  .react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    height: 42px; /* Garante que todos tenham a mesma altura */
    transition: border-color 0.3s ease;
  }
  
  /* Foco padronizado para inputs e selects */
  .conta-receber-avulso-form input:focus,
  .conta-receber-avulso-form select:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52,152,219,0.1);
  }
  
  /* Estilização do DatePicker para se parecer com os outros inputs */
  .react-datepicker-wrapper {
    width: 100%;
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    display: block;
  }
  
  .react-datepicker__input-container {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    transition: border-color 0.3s ease;
    background: white;
  }
  
  .react-datepicker__input-container input:focus {
    outline: none;
    border-color: #1E3A8A;
    box-shadow: 0 0 5px rgba(30, 58, 138, 0.2);
  }
  
  /* Seção de Projetos */
  .projetos-section {
    margin-bottom: 20px;
  }
  
  .projetos-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #34495e;
  }
  
  .projeto-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .projeto-item select,
  .projeto-item input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    height: 40px;
    transition: border-color 0.3s ease;
  }
  
  .projeto-item select:focus,
  .projeto-item input:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52,152,219,0.1);
  }
  
  .projeto-item button {
    background-color: #e03131;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 0 12px;
    cursor: pointer;
    height: 40px;
    transition: background-color 0.3s ease;
  }
  
  .projeto-item button:hover {
    background-color: #c92a2a;
  }
  
  .projetos-section button {
    background-color: #3498db;
    color: #ffffff;
    padding: 7px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }
  
  .projetos-section button:hover {
    background-color: #2980b9;
  }
  
  /* Botão de submit */
  .conta-receber-avulso-form button[type="submit"] {
    background-color: #3498db;
    color: #ffffff;
    padding: 7px 15px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    width: 100%;
    height: 45px;
  }
  
  .conta-receber-avulso-form button[type="submit"]:hover {
    background-color: #2980b9;
  }
  
  /* Mensagens de erro e sucesso */
  .error {
    color: red;
    margin-top: 10px;
  }
  
  .success {
    color: #009C95;
    margin-top: 10px;
  }
  
  /* Caso você envolva a listagem em um container similar */
  .cpa-list-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(148,146,146,0.1);
    margin: 20px auto;
    width: 100%;
    max-width: 1700px;
  }
  
  .total-display-cra {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 42px;
    display: flex;
    align-items: center;
    background-color: #f0f0f0; /* Fundo diferenciado para indicar que não é editável */
    color: #333;
  }
  