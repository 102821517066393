/* Container geral do layout */
.cliente-form-container {
    display: flex;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding-right: 20px;
    padding-top: 1px;
}

.sidebar-container {
    grid-column: 1;
    
}
.cliente-form-sidebar {
    width: 25%;
    background: linear-gradient(135deg, #037C74, #65C1C2);
    color: white;
    padding: 20px;
    box-sizing: border-box;
    height: 97vh;
    margin-top: 20px;
    border-radius: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
    


/* Container principal do formulário */
.c-main-content {
    display: flex;
    width: 69%;
    background-color: white;
    border-radius: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 97vh;
    margin-top: 20px;
    margin-left: 10px;
    
}

.c-cliente-form-container {
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    height: 75vh;
    width: 85%;
    position: relative;
    left: 10%;
    
}

/* Estilização geral do formulário */
.c-cliente-form-container h2 {
    text-align: left;
    margin-bottom: 20px;
    color: #333;
}

.c-cliente-form-container form {
    width: 100%;
    padding: 10px;
    margin-right: 80px;
    
}

/* Estilização das linhas do formulário */
.c-form-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
}

.c-form-group {
    flex: 1;
    min-width: 250px;
    padding: 0 10px;
    margin-bottom: 15px;
}

/* Estilização dos labels */
.c-cliente-form-container label {
    display: block;
    margin-bottom: 15px;
    font-weight: bold;
    color: #555;
    font-size: 0.9em;
}

/* Estilo para inputs menores */
.c-cliente-form-container input.input-pequeno[type="text"] {
    width: 120px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.c-cliente-form-container input.input-medio[type="text"] {
    width: 250px; /* Tamanho típico para campos de telefone */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

/* Estilo para inputs de email (mesmo estilo que input-grande) */
.c-cliente-form-container input.input-grande[type="email"] {
    width: 100%; /* Tamanho típico para campos de email */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.c-cliente-form-container input.input-grande[type="endereco"] {
    width: 100%; /* Tamanho típico para campos de email */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

/* Estilo para inputs maiores */
.c-cliente-form-container input.input-grande[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    font-size: 0.9em;
}

/* Override other general styles if needed */
form input.input-pequeno[type="text"] {
    width: 120px !important;
}
form input.input-grande[type="text"] {
    width: 100% !important;
}


/* Estilização dos campos de input */
.c-cliente-form-container input[type="text"],
.c-cliente-form-container input[type="date"],
.c-cliente-form-container select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    font-size: 0.9em;
}

/* Estilização das ações do formulário */
.c-form-actions {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

/* Estilização dos botões */
.c-btn-primary {
    background-color: #009C95 !important;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9em;
}

.c-btn-primary:hover {
    background-color: #01726c !important; 
}

.c-btn-secondary {
    background-color: #6c757d;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9em;
    position: relative;
}

.c-btn-secondary:hover {
    background-color: #5a6268;
}

.cliente-form-sidebar .additional-info {
    background-color: #47494c; /* Blue background color */
    color: white;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px; /* Add spacing below the blue section */
    flex-grow: 1;
}


.Toastify__toast-container {
    width: 320px;
    font-family: 'Segoe UI', system-ui, sans-serif;
  }
  
  .Toastify__toast {
    border-radius: 12px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
    padding: 16px 20px !important;
    margin-bottom: 1rem !important;
    align-items: flex-start !important;
    position: relative;
    overflow: hidden;
  }
  
  .Toastify__toast::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
  }
  
  .Toastify__toast--success {
    background: linear-gradient(135deg, #f0fff4, #ffffff) !important;
    border: 1px solid #38a169 !important;
  }
  
  .Toastify__toast--success::before {
    background: #38a169;
  }
  
  .Toastify__toast--error {
    background: linear-gradient(135deg, #fff5f5, #ffffff) !important;
    border: 1px solid #e53e3e !important;
  }
  
  .Toastify__toast--error::before {
    background: #e53e3e;
  }
  
  .Toastify__toast-body {
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center;
    gap: 12px;
  }
  
  .Toastify__toast-icon {
    width: 24px !important;
    height: 24px !important;
    margin-right: 0 !important;
    flex-shrink: 0;
  }
  
  .Toastify__toast-message {
    color: #2d3748 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 1.5;
    margin: 0 !important;
  }
  
  /* Ícones personalizados usando pseudo-elementos */
  .Toastify__toast--success .Toastify__toast-icon::after {
    content: '✓';
    color: #38a169;
    font-size: 18px;
    font-weight: bold;
  }
  
  .Toastify__toast--error .Toastify__toast-icon::after {
    content: '✕';
    color: #e53e3e;
    font-size: 18px;
    font-weight: bold;
  }
  
  .Toastify__progress-bar {
    height: 3px !important;
  }
  
  .Toastify__progress-bar--success {
    background: #38a169 !important;
  }
  
  .Toastify__progress-bar--error {
    background: #e53e3e !important;
  }
  
  /* Animação personalizada */
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  
  .Toastify__slide-enter {
    animation: slideIn 0.3s ease-out;
  }
  
  .Toastify__slide-exit {
    animation: slideOut 0.3s ease-in;
  }

@media (max-width: 768px) { 
    .layout-container {
      grid-template-columns: 1fr; /* Stack columns vertically on smaller screens */
    }
  }