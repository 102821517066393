/* ContaPagarForm.css - Ajustado para se parecer com ContaReceberForm */
.container {
  padding: 20px;
  flex-grow: 1;
  background-color: #f7f7f7;
  /* Remover height: 110vh */
  min-height: 100vh;
  /*margin-left: 250px;  Largura do sidebar */
  overflow-y: auto; /* Scroll apenas no conteúdo principal */
  width: calc(100% - 250px); /* Largura total menos o sidebar */
}

.cp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cp-header h1 {
  font-size: 24px;
  color: #2C3E50;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info img {
  border-radius: 50%;
  margin-right: 10px;
}

.summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.meu-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(148, 146, 146, 0.1);
  flex: 1;
  margin-right: 20px;
  margin-left: 20px;
}

.meu-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #34495e;
}

.meu-card p {
  font-size: 24px;
  font-weight: 700;
  color: #2ace9d;
}

.contract-info {
  background-color: #f1f4f9;
  padding: 15px;
  border-radius: 8px;
  border-left: 5px solid #3498db;
  margin: 0 20px 20px 16px;
}

.contract-info p {
  margin-bottom: 10px;
  font-size: 16px;
  color: #2C3E50;
}

.form-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(148, 146, 146, 0.1);
  margin: 0 20px 20px 16px;
  overflow-x: auto;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  min-width: 250px;
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  color: #2C3E50;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

.form-group.full-width {
  flex: 1 0 100%;
}

.cp-submit-btn {
  background-color: #3498db;
  color: #ffffff;
  padding: 7px 15px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  width: auto;
}

.cp-submit-btn:hover {
  background-color: #2980b9;
}

.botao-preencher {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 15px;
  float: right;
}

.botao-preencher:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
}

.success-message {
  color: #009C95;
  margin-bottom: 10px;
}

.Toastify__toast-container {
  width: 320px;
  font-family: 'Segoe UI', system-ui, sans-serif;
}

.Toastify__toast {
  border-radius: 12px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  padding: 16px 20px !important;
  margin-bottom: 1rem !important;
  align-items: flex-start !important;
  position: relative;
  overflow: hidden;
}

.Toastify__toast::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
}

.Toastify__toast--success {
  background: linear-gradient(135deg, #f0fff4, #ffffff) !important;
  border: 1px solid #38a169 !important;
}

.Toastify__toast--success::before {
  background: #38a169;
}

.Toastify__toast--error {
  background: linear-gradient(135deg, #fff5f5, #ffffff) !important;
  border: 1px solid #e53e3e !important;
}

.Toastify__toast--error::before {
  background: #e53e3e;
}

.Toastify__toast-body {
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center;
  gap: 12px;
}

.Toastify__toast-icon {
  width: 24px !important;
  height: 24px !important;
  margin-right: 0 !important;
  flex-shrink: 0;
}

.Toastify__toast-message {
  color: #2d3748 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.5;
  margin: 0 !important;
}

/* Ícones personalizados usando pseudo-elementos */
.Toastify__toast--success .Toastify__toast-icon::after {
  content: '✓';
  color: #38a169;
  font-size: 18px;
  font-weight: bold;
}

.Toastify__toast--error .Toastify__toast-icon::after {
  content: '✕';
  color: #e53e3e;
  font-size: 18px;
  font-weight: bold;
}

.Toastify__progress-bar {
  height: 3px !important;
}

.Toastify__progress-bar--success {
  background: #38a169 !important;
}

.Toastify__progress-bar--error {
  background: #e53e3e !important;
}

/* Animação personalizada */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.Toastify__slide-enter {
  animation: slideIn 0.3s ease-out;
}

.Toastify__slide-exit {
  animation: slideOut 0.3s ease-in;
}