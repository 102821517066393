/* ContaPagarList.css */

.registro-conta-pagar-list {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 12px;
    /*box-shadow: 0px 5px 20px rgba(148, 146, 146, 0.1);*/
    max-width: 100%; /* Ajuste esta porcentagem conforme necessário */
    margin: 0 auto; /* Centraliza o componente horizontalmente */
  }
  
  .registro-conta-pagar-list h2 {
    font-size: 24px;
    color: #34495e;
    margin-bottom: 20px;
  }
  
  .registro-contas-pagar-container {
    overflow-x: auto;
    padding: 20px;
  }

  
table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 5px 20px rgba(148, 146, 146, 0.1);
}
  
  thead th {
    background-color: #009C95;
    color: #ffffff;
    font-weight: 600;
    padding: 12px;
    text-align: left;
    font-size: 16px;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
  
  tbody td,
  thead th {
    padding: 12px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
    color: #34495e;
  }
  
  tbody td:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .edit-button-pl ,
  .delete-button-pl {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .edit-button-pl:hover {
    background-color: #e0f7fa;
  }
  
  .delete-button-pl:hover {
    background-color: #ffebee;
  }
  
  .edit-button-pl svg {
    color: #3498db;
  }
  
  .delete-button-pl svg {
    color: #e74c3c;
  }
  
  /* Estilização para mensagens de erro e sucesso */
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .success-message {
    color: #009C95;
    font-size: 14px;
    margin-top: 10px;
  }
  