/* Container principal */
.projects-list {
  flex-grow: 1;
  margin: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Título */
.projects-list h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
}

/* Botões de Ação */
.projects-list .actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.projects-list .actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.projects-list .actions .add-button {
  background-color: #007bff;
  color: white;
}

.projects-list .actions .add-button:hover {
  background-color: #0056b3;
}

.projects-list .actions .delete-button {
  background-color: #dc3545;
  color: white;
}

.projects-list .actions .delete-button:hover {
  background-color: #c82333;
}

/* Input de Pesquisa */
.projects-list .actions input {
  padding: 10px;
  border: 1px solid #0cbd91;
  border-radius: 5px;
  width: 250px;
  flex-grow: 0;
  margin-right: auto; /* Empurra o input para a esquerda */
}

/* Estilização da tabela */
.projects-list table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}

.projects-list th, .projects-list td {
  padding: 12px 15px;
  text-align: left;
}

.projects-list th {
  background-color: #f1f1f1;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid #dee2e6;
}

.projects-list td {
  border-bottom: 1px solid #dee2e6;
  color: #555;
}

.projects-list tr:hover {
  background-color: #f9f9f9;
}

/* Checkbox */
.projects-list input[type="checkbox"] {
  margin-right: 10px;
}

/* Centraliza o botão de imprimir */
.projects-list .print-button-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.projects-list .print-button {
  background-color: #6c757d;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.projects-list .print-button:hover {
  background-color: #5a6268;
}

/* Container flexível da página */
.projects-list-container {
  display: flex;
  height: 100vh; /* Faz com que o container ocupe a altura total da tela */
}

/* Sidebar ocupa um espaço fixo */
.sidebar {
  width: 350px;
  background-color: #343a40;
  color: white;
  padding: 15px;
  flex-shrink: 0;
}
