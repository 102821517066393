body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f6f9;
  margin: 0;
  padding: 0;
  color: #2c3e50;
}

.dashboard {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.sidebar h2 {
  color: #ecf0f1;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 15px;
}

.sidebar ul li a {
  color: #bdc3c7;
  text-decoration: none;
  font-size: 1.1em;
  display: block;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.sidebar ul li a:hover {
  background-color: #34495e;
}

.main-content {
  flex: 1;
  padding: 20px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h1 {
  font-size: 2em;
  color: #34495e;
}

.filters {
  display: flex;
  align-items: center;
}

.filter-item {
  margin-right: 20px;
}

.filters label {
  margin-right: 10px;
  font-weight: bold;
}

.filters input {
  padding: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 1em;
}

.filters button {
  padding: 10px 20px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filters button:hover {
  background-color: #2ecc71;
}

.error {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}

.stats-overview {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stat-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  flex: 1;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.stat-card:last-child {
  margin-right: 0;
}

.stat-card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #27ae60;
}

.stat-card p {
  font-size: 1.8em;
  font-weight: bold;
  margin: 0;
  color: #34495e;
}

.table-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 780px;
  overflow-y: auto;
}

.table-container h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #34495e;
  
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table th, .table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ecf0f1;
}

.table th {
  background-color: #2980b9;
  color: white;
  font-weight: bold;
}

.table td {
  background-color: #f9f9f9;
  color: #34495e;
}

.table tr:hover {
  background-color: #ecf0f1;
}
