/* ContaReceberList.css */

.conta-receber-list {
  padding: 20px;
    background-color: #f7f7f7;
    border-radius: 12px;
    /*box-shadow: 0px 5px 20px rgba(148, 146, 146, 0.1);*/
    max-width: 100%; /* Ajuste esta porcentagem conforme necessário */
    margin-right: 20px; /* Centraliza o componente horizontalmente */
}

.conta-receber-list h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 5px 20px rgba(148, 146, 146, 0.1);
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  color: #333;
}

tr:hover {
  background-color: #f9f9f9;
}

.edit-button, .delete-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.edit-button:hover {
  background-color: #e0f7fa;
}

.delete-button:hover {
  background-color: #ffebee;
}

.edit-button svg, .delete-button svg {
  width: 16px;
  height: 16px;
  color: #333;
}

.edit-button svg {
  color: #009688; /* Cor para o ícone de edição */
}

.delete-button svg {
  color: #f44336; /* Cor para o ícone de exclusão */
}
