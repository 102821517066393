/* Padronizado com o estilo do ContaPagarForm */

.container {
  padding: 20px;
  flex-grow: 1;
  background-color: #f7f7f7;
  height: 110vh;

  overflow-y: auto; /* Scroll apenas no conteúdo principal */
  width: calc(100% - 250px); /* Largura total menos o sidebar */
}

.cp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cp-header h1 {
  font-size: 24px;
  color: #2C3E50;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info img {
  border-radius: 50%;
  margin-right: 10px;
}

.contract-info {
  background-color: #f1f4f9;
  padding: 15px;
  border-radius: 8px;
  border-left: 5px solid #3498db;
  margin: 0 20px 20px 16px;
}

.contract-info p {
  margin-bottom: 10px;
  font-size: 16px;
  color: #2C3E50;
}

.projetos-info {
  margin-top: 15px;
}

.projetos-info h4 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #34495e;
}

.projeto-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  color: #2C3E50;
}

.form-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(148, 146, 146, 0.1);
  margin: 0 20px 20px 16px;
  overflow-x: auto;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  min-width: 250px;
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  color: #2C3E50;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

.form-group.full-width {
  flex: 1 0 100%;
}

.cp-submit-btn {
  background-color: #3498db;
  color: #ffffff;
  padding: 7px 15px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.cp-submit-btn:hover {
  background-color: #2980b9;
}

/* Estilização do Toast (opcional) */
.Toastify__toast {
  border-radius: 8px !important;
  font-family: 'Segoe UI', system-ui, sans-serif !important;
}
.Toastify__toast--success {
  background: #40c057 !important;
}
.Toastify__toast--error {
  background: #e03131 !important;
}
