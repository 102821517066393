.funcionario-list-container {
    display: flex;
    min-height: 100vh;
    background-color: #f5f7fa;
  }
  
  .funcionario-list {
    flex: 1;
    padding: 2rem;
  }
  
  .actions {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }
  
  input[type="text"] {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex: 1;
    max-width: 300px;
  }
  
  button {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .add-button {
    background-color: #009C95;
    color: white;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #009C95;
    color: white;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  
  .button-custom-edit {
    background-color: #ffc107;
    color: black;
    padding: 0.5rem 1rem;
  }