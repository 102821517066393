/* RegisterForm.css (mesmo estilo do LoginForm.css) */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f0f4f8;
  font-family: 'Roboto', sans-serif;
}

.login-container {
  display: flex;
  height: 100vh;
}

.login-color {
  flex: 1;
  background: linear-gradient(135deg, #037C74, #65C1C2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  max-width: 900px;
  padding: 100px;
  color: white;
}

.login-form h2 {
  text-align: center;
  color: #037C74;
  font-size: 28px;
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  letter-spacing: 1.2px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffffff;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #94D6DA;
  box-sizing: border-box;
  border-radius: 5px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input:focus {
  border-color: #ffffff;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  outline: none;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  text-align: center;
}

.success-message {
  color: #28a745;
  font-size: 14px;
  text-align: center;
}

.submit-button {
  width: 100%;
  padding: 12px;
  border: none;
  background-color: #037C74 !important;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.submit-button:hover {
  background-color: #023e3a;
  transform: scale(1.02);
}

@media screen and (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-color {
    display: none;
  }

  .login-form {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
}

.logo {
  max-width: 200px; /* Ajuste o tamanho máximo da logo conforme necessário */
  height: auto; /* Mantém a proporção da imagem */
  right: 10px;
}

.link-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #037C74;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  font-weight: bold;
}

.link-button:hover {
  background-color: #023e3a;
  transform: scale(1.05);
}
