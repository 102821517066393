.contrato-form-container {
    display: flex;
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.ctr-main-content {
    width: 67%;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    margin-left: 50px;
    margin-top: 20px;
    height: 97vh;
}

.contrato-info-bar {
    width: 25%;
    background: linear-gradient(135deg, #037C74, #65C1C2);
    color: white;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
}

.input-descricao {
    width: 100%; /* Ocupa 100% da largura do contêiner */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    font-family: inherit; /* Usa a mesma fonte do restante do formulário */
    resize: vertical; /* Permite redimensionar verticalmente */
}

.contrato-info-bar h3,
.ctr-main-content h2 {
    margin-top: 0;
}

.ctr-contrato-form-container {
    display: flex;
    flex-direction: column;
}

.ctr-contrato-form-container h2{
    text-align: left;
    margin-bottom: 20px;
    color: #333;
}

.ctr-form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.ctr-form-group {
    display: flex;
    flex-direction: column;
}

.ctr-form-group label {
    font-weight: bold;
    margin-bottom: 8px;
}

.ctr-form-group input,
.ctr-form-group select,
.ctr-form-group textarea {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
}


.ctr-form-section {
    display: flex;
    flex-direction: column; /* Organiza os elementos em coluna */
    margin-bottom: 20px; 
}
/* Centralizar o título "Conta a pagar" abaixo dos selects */
.ctr-form-section {
    text-align: center;
    margin-bottom: 20px;
}

/* Alinhar os campos "Fornecedor", "Valor da Parcela" e "Periodicidade de Vencimento" em uma linha */
.ctr-form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Espaçamento entre os campos */
    margin-bottom: 20px; /* Espaçamento abaixo da linha */
}

/* Estilo para garantir que todos os campos fiquem na mesma altura */
.ctr-form-group {
    flex: 1;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.modal-close-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-close-btn:hover {
    background-color: #0056b3;
}



/* Estilo específico para o input de "Valor da Parcela" */
.ctr-form-group input[type="text"],
.ctr-form-group select {
    width: 100%;
}

/* Ajustar o margin e padding dos h3 */
.ctr-form-section h3 {
    margin: 0;
    padding: 10px 0;
}

/* Alinhar os labels acima dos campos */
.ctr-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
    font-size: 0.9em;
}

/* Ajustes adicionais se necessário */
.ctr-form-row .ctr-form-group:not(:last-child) {
    margin-right: 20px; /* Espaçamento entre os grupos de formulário */
}


/* Estilização dos botões */

.projecao-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
}

.projecao-table th, .projecao-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

.projecao-table thead tr {
    background-color: #f2f2f2;
}

.projecao-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.projecao-table tbody tr:hover {
    background-color: #f1f1f1;
}

.modal-close-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #a13333;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.modal-close-btn:hover {
    background-color: #702727;
}


.modal-confirm-btn {
    margin-top: 20px;
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #1da789;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.modal-confirm-btn:hover {
    background-color: #087e64;
}



/* Botões Base */
.ctr-btn {
    padding: 0.8em 1.5em;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 0.9em;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .ctr-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Efeito hover genérico */
  .ctr-btn:not(:disabled):hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Botão Primário (Principal) */
  .ctr-btn-primary {
    background: linear-gradient(135deg, #009C95, #01746F);
    color: white;
    border: 1px solid #016862;
  }
  
  .ctr-btn-primary:hover:not(:disabled) {
    background: linear-gradient(135deg, #01746F, #015953);
  }
  
  /* Botão Secundário (Cancelar/Fechar) */
  .ctr-btn-secondary {
    background: transparent;
    color: #6B7280;
    border: 1px solid #E5E7EB;
  }
  
  .ctr-btn-secondary:hover:not(:disabled) {
    background: #F9FAFB;
    border-color: #D1D5DB;
  }
  
  /* Botão de Sucesso (Confirmar) */
  .ctr-btn-success {
    background: linear-gradient(135deg, #10B981, #059669);
    color: white;
    border: 1px solid #047857;
  }
  
  .ctr-btn-success:hover:not(:disabled) {
    background: linear-gradient(135deg, #059669, #047857);
  }
  
  /* Botão de Perigo (Remover) */
  .ctr-btn-danger {
    background: linear-gradient(135deg, #EF4444, #DC2626);
    color: white;
    border: 1px solid #B91C1C;
  }
  
  .ctr-btn-danger:hover:not(:disabled) {
    background: linear-gradient(135deg, #DC2626, #B91C1C);
  }
  
  /* Botão de Adição */
  .ctr-btn-add {
    background: linear-gradient(135deg, #3B82F6, #2563EB);
    color: white;
    border: 1px solid #1D4ED8;
    margin-top: 18px;
  }
  
  .ctr-btn-add:hover:not(:disabled) {
    background: linear-gradient(135deg, #2563EB, #1D4ED8);
  }
  
  /* Ícones usando pseudo-elementos */
  .ctr-btn-add::before {
    content: '+';
    font-weight: bold;
    font-size: 1.1em;
  }
  
  .ctr-btn-danger::before {
    content: '×';
    font-size: 1.3em;
    line-height: 0;
  }
  
  .ctr-btn-success::after {
    content: '✓';
    margin-left: 5px;
  }
  
  /* Efeito de loading (opcional) */
  .ctr-btn-loading::after {
    content: "";
    width: 16px;
    height: 16px;
    border: 3px solid rgba(255,255,255,0.3);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }


/* Adicione isto ao seu arquivo CSS global ou no componente */
.Toastify__toast-container {
    width: 320px;
    font-family: 'Segoe UI', system-ui, sans-serif;
  }
  
  .Toastify__toast {
    border-radius: 12px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
    padding: 16px 20px !important;
    margin-bottom: 1rem !important;
    align-items: flex-start !important;
    position: relative;
    overflow: hidden;
  }
  
  .Toastify__toast::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
  }
  
  .Toastify__toast--success {
    background: linear-gradient(135deg, #f0fff4, #ffffff) !important;
    border: 1px solid #38a169 !important;
  }
  
  .Toastify__toast--success::before {
    background: #38a169;
  }
  
  .Toastify__toast--error {
    background: linear-gradient(135deg, #fff5f5, #ffffff) !important;
    border: 1px solid #e53e3e !important;
  }
  
  .Toastify__toast--error::before {
    background: #e53e3e;
  }
  
  .Toastify__toast-body {
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center;
    gap: 12px;
  }
  
  .Toastify__toast-icon {
    width: 24px !important;
    height: 24px !important;
    margin-right: 0 !important;
    flex-shrink: 0;
  }
  
  .Toastify__toast-message {
    color: #2d3748 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 1.5;
    margin: 0 !important;
  }
  
  /* Ícones personalizados usando pseudo-elementos */
  .Toastify__toast--success .Toastify__toast-icon::after {
    content: '✓';
    color: #38a169;
    font-size: 18px;
    font-weight: bold;
  }
  
  .Toastify__toast--error .Toastify__toast-icon::after {
    content: '✕';
    color: #e53e3e;
    font-size: 18px;
    font-weight: bold;
  }
  
  .Toastify__progress-bar {
    height: 3px !important;
  }
  
  .Toastify__progress-bar--success {
    background: #38a169 !important;
  }
  
  .Toastify__progress-bar--error {
    background: #e53e3e !important;
  }
  
  /* Animação personalizada */
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  
  .Toastify__slide-enter {
    animation: slideIn 0.3s ease-out;
  }
  
  .Toastify__slide-exit {
    animation: slideOut 0.3s ease-in;
  }