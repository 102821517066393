/* ConfirmModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
  }
  
  .modal-container {
    background: #fff;
    padding: 20px;
    border-radius: 9px;
    width: 90%;
    max-width: 500px;
  }
  
  .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-actions button {
    margin-left: 10px;
  }

  .cp-confirm-btn{
    background-color: #009C95;
    color: #ffffff;
    padding: 7px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }

  .cp-cancel-btn{
    background-color: #da463c;
    color: #ffffff;
    padding: 7px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }
  